import React, { useContext } from 'react';
import { bool, string } from 'prop-types';
import { reportToSegment, eventNames, types } from '@smartcar/morse';
import AppContext from '../../context/AppContext';

import Icon from './Icon';

const BackToAppLink = ({ arrow, classnames }) => {
  const { referringAppName, referringAppUrl } = useContext(AppContext);
  const buttonText = `Back to ${referringAppName}`;
  return (
    <a
      id="back-to-app-btn"
      className={`button-cta no-underline cursor-pointer ${classnames}`}
      href={referringAppUrl}
      onClick={() =>
        reportToSegment(types.TRACK, eventNames.linkClicked, {
          section: 'topbar',
          style: 'cta primary',
          text: buttonText,
          path: referringAppUrl,
        })
      }
    >
      {buttonText}
      {arrow && (
        <>
          {' '}
          <Icon name="arrow" />
        </>
      )}
    </a>
  );
};

export default BackToAppLink;

BackToAppLink.propTypes = {
  classnames: string,
  arrow: bool,
};

BackToAppLink.defaultProps = {
  classnames: '',
  arrow: false,
};
