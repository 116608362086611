import React from 'react';
import { string } from 'prop-types';

import { ReactComponent as ArrowIcon } from '../../images/icons/arrow.svg';
import { ReactComponent as DollarSignIcon } from '../../images/icons/dollar-sign.svg';
import { ReactComponent as GlobeIcon } from '../../images/icons/globe.svg';
import { ReactComponent as MenuIcon } from '../../images/icons/menu.svg';
import { ReactComponent as PhoneIcon } from '../../images/icons/phone.svg';

const iconTypes = {
  arrow: ArrowIcon,
  dollarSign: DollarSignIcon,
  globe: GlobeIcon,
  menu: MenuIcon,
  phone: PhoneIcon,
};

const Icon = ({ name, classnames }) => {
  const IconSvg = iconTypes[name] || MenuIcon;
  return <IconSvg className={`inline-block -mt-1 ${classnames}`} aria-hidden="true" />;
};

export default Icon;

Icon.propTypes = {
  name: string.isRequired,
  classnames: string,
};

Icon.defaultProps = {
  classnames: '',
};
