import React from 'react';
import { arrayOf, func, shape, string } from 'prop-types';

const Select = ({ classnames, defaultValue, id, onChange, options }) => {
  return (
    <select
      className={`cursor-pointer rounded ${classnames}`}
      id={id}
      defaultValue={defaultValue}
      onChange={(e) => onChange(e.target.value)}
    >
      {options.map((option, index) => {
        return (
          <option value={option.value} key={index}>
            {option.label}
          </option>
        );
      })}
    </select>
  );
};

export default Select;

Select.propTypes = {
  classnames: string,
  defaultValue: string,
  id: string,
  options: arrayOf(shape({ value: string, label: string })).isRequired,
  onChange: func,
};

Select.defaultProps = { classnames: '', defaultValue: '', id: '', onChange: null };
