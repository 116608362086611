import React from 'react';

const defaultAppContext = {
  referringAppName: '',
  referringAppUrl: '',
};

const AppContext = React.createContext(defaultAppContext);

export default AppContext;
