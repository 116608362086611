import React from 'react';
import { bool, func, node, shape, string } from 'prop-types';
import { reportToSegment, eventNames, types } from '@smartcar/morse';

const Button = ({ text, id, type, onClick, disabled, classnames, children, segmentProperties }) => {
  const handleClick = () => {
    if (segmentProperties)
      reportToSegment(types.TRACK, eventNames.buttonClicked, segmentProperties);
    if (onClick) onClick();
  };
  return (
    <button
      id={id}
      // eslint-disable-next-line react/button-has-type
      type={type}
      className={`button-cta ${classnames} ${disabled ? 'button-disabled' : ''}`}
      onClick={handleClick}
      disabled={disabled}
    >
      {text || children}
    </button>
  );
};

export default Button;

Button.propTypes = {
  text: string,
  type: string,
  id: string,
  onClick: func,
  disabled: bool,
  children: node,
  classnames: string,
  segmentProperties: shape({
    label: string.isRequired,
    text: string.isRequired,
  }),
};

Button.defaultProps = {
  text: '',
  id: null,
  type: 'button',
  onClick: null,
  disabled: false,
  children: null,
  classnames: '',
  segmentProperties: null,
};
