import React from 'react';
import { arrayOf, node, oneOfType } from 'prop-types';

import { Nav } from '../components';

const Layout = ({ children }) => {
  return (
    <>
      <Nav />
      <div className="pt-14 md:pt-24">{children}</div>
    </>
  );
};

export default Layout;

Layout.propTypes = {
  children: oneOfType([arrayOf(node), node]).isRequired,
};
