import React from 'react';
import { Link as GatsbyLink } from 'gatsby';
import { func, node, object, shape, string } from 'prop-types';
import { eventNames, reportToSegment, types } from '@smartcar/morse';

const Link = ({
  children,
  path,
  segmentProperties,
  id,
  text,
  classnames,
  onClick,
  activeStyle,
}) => {
  const handleClick = () => {
    if (segmentProperties) {
      reportToSegment(types.TRACK, eventNames.linkClicked, segmentProperties);
    }
    if (onClick) {
      onClick();
    }
  };
  return (
    <GatsbyLink
      to={path}
      onClick={handleClick}
      className={classnames}
      id={id}
      activeStyle={activeStyle}
    >
      {children || text}
    </GatsbyLink>
  );
};

export default Link;

Link.propTypes = {
  path: string.isRequired,
  segmentProperties: shape({
    section: string.isRequired,
    style: string.isRequired,
    text: string.isRequired,
    path: string.isRequired,
  }).isRequired,
  children: node,
  text: string,
  classnames: string,
  id: string,
  onClick: func,
  // eslint-disable-next-line react/forbid-prop-types
  activeStyle: object,
};

Link.defaultProps = {
  children: null,
  text: '',
  id: '',
  classnames: '',
  onClick: null,
  activeStyle: null,
};
