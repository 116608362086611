import React from 'react';
import { Helmet } from 'react-helmet';
import { shape, string } from 'prop-types';

import favicon from '../images/icons/favicon.ico';

const BASE_URL = 'https://connectyourcar.com';

const SEO = ({ seo, path, lang }) => {
  const { title, metaDescription } = seo;

  const pathname = path === 'index' ? '/' : `/${path}/`;

  const url = `${BASE_URL}${pathname}`;
  return (
    <Helmet
      htmlAttributes={{
        lang,
      }}
      title={title}
      meta={[
        { name: 'description', content: metaDescription },
        { property: 'og:title', content: title },
        { property: 'og:description', content: metaDescription },
        { property: 'og:type', content: 'website' },
        { property: 'og:url', content: url },
        { name: 'twitter:title', content: title },
        { name: 'twitter:description', content: metaDescription },
      ]}
      link={[
        { rel: 'shortcut icon', type: 'image/png', href: favicon },
        { rel: 'canonical', href: url },
      ]}
    />
  );
};

export default SEO;

SEO.propTypes = {
  seo: shape({
    title: string.isRequired,
    metaDescription: string.isRequired,
  }).isRequired,
  path: string.isRequired,
  lang: string,
};

SEO.defaultProps = {
  lang: 'en',
};
