import { shape, string } from 'prop-types';
import React, { useState } from 'react';
import parse from 'html-react-parser';
import { navigate } from 'gatsby';

import expand from '../../images/icons/arrow-right.svg';
import deflate from '../../images/icons/arrow-down.svg';

const FaqQuestion = ({ content }) => {
  const isLinked = () => {
    if (
      typeof window !== 'undefined' &&
      window.location.href.substring(window.location.href.lastIndexOf('/') + 1) ===
        `#${content.deepLinkId}`
    ) {
      return true;
    }
    return false;
  };

  const [open, setOpen] = useState(isLinked());

  const handleOpen = () => {
    if (!open) {
      navigate(`#${content.deepLinkId}`);
    } else {
      window.history.pushState(null, '', ' ');
    }
    setOpen(!open);
  };

  return (
    <div className="pb-6">
      <button onClick={handleOpen} type="button" className="w-full">
        <div className="flex justify-start items-center">
          <img
            src={open ? deflate : expand}
            alt="caret"
            aria-hidden="true"
            className="w-10 flex-none"
          />

          <h3 id={content.deepLinkId} className="text-left">
            {content.question}
          </h3>
        </div>
      </button>
      {open && <div className={`pl-10 pb-2 `}>{parse(content.answer.html)}</div>}
    </div>
  );
};

export default FaqQuestion;

FaqQuestion.propTypes = {
  content: shape({
    question: string.isRequired,
    answer: shape({ html: string.isRequired }).isRequired,
    deepLinkId: string.isRequired,
  }).isRequired,
};
