import { arrayOf, shape, string } from 'prop-types';
import React from 'react';

import FaqQuestion from './FaqQuestion';

const FAQ = ({ content }) => {
  return (
    <div className="container bg-white pt-8 lg:px-36">
      <h2 className="pb-10">Frequently asked questions</h2>
      {content.map((faq, index) => (
        <FaqQuestion content={faq} key={index} />
      ))}
    </div>
  );
};

export default FAQ;

FAQ.propTypes = {
  content: arrayOf(
    shape({
      question: string.isRequired,
      answer: shape({ html: string.isRequired }).isRequired,
      deepLinkId: string.isRequired,
    }),
  ).isRequired,
};
