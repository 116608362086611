import React, { useState } from 'react';
import { arrayOf, bool, shape } from 'prop-types';
import { Select } from '../elements';

import BrandCard from './BrandCard';
import regions from '../../../config/regions';
import customBrandConfigs from '../../../config/customBrands';

const BrandSelector = ({ brands, linkToGuide }) => {
  const [region, setRegion] = useState(regions[0].value);

  const staticText = {
    regionSelection: {
      text: `Select your country or region:`,
    },
    backToMakesCta: {
      text: ' Back to makes',
    },
  };

  const regionPath = regions.find((option) => region === option.value).path;
  const customBrands = customBrandConfigs.filter((custom) => custom.region === region);
  let brandSeen = '';

  const brandsSorted = brands
    .filter((oem) => {
      return region === oem.node.region;
    })
    .sort((a, b) => {
      if (a.node.manufacturer < b.node.manufacturer) {
        return -1;
      }
      if (a.node.manufacturer > b.node.manufacturer) {
        return 1;
      }
      return 0;
    })
    .map((oem) => {
      if (oem.node.manufacturer === brandSeen) {
        return {};
      }
      if (
        customBrands.some((custom) => oem.node.manufacturer.toLowerCase() === custom.manufacturer)
      ) {
        brandSeen = oem.node.manufacturer;
        return {
          brand: oem.node.manufacturer,
          logo: oem.node.manufacturerLogo,
          path: regionPath
            ? `${regionPath}/${oem.node.manufacturer.replace(/\s+/g, '-').toLowerCase()}`
            : oem.node.manufacturer.replace(/\s+/g, '-').toLowerCase(),
        };
      }
      return {
        brand: oem.node.manufacturer,
        logo: oem.node.manufacturerLogo,
        path: oem.node.path,
      };
    })
    .filter((oem) => {
      return Object.keys(oem).length;
    });

  return (
    <div className="container">
      <div className="md:pb-4">
        <div>{staticText.regionSelection.text}</div>
        <Select
          id="region"
          key={region}
          classnames="w-60 sm:w-80 h-14 p-3 my-4 font-bold bg-white border-2 border-grey-medium hover:border-black"
          defaultValue={region}
          onChange={setRegion}
          options={regions}
        />
      </div>
      <div className="px-0 grid gap-4 md:gap-8 grid-cols-2 md:grid-cols-3 lg:grid-cols-4">
        {brandsSorted.map((brand, index) => {
          return (
            <BrandCard {...brand} regionPath={regionPath} linkToGuide={linkToGuide} key={index} />
          );
        })}
      </div>
    </div>
  );
};

export default BrandSelector;

BrandSelector.propTypes = {
  brands: arrayOf(shape(BrandCard.propTypes)).isRequired,
  linkToGuide: bool,
};

BrandSelector.defaultProps = {
  linkToGuide: false,
};
