/* eslint-disable no-plusplus */
/* eslint-disable no-restricted-globals */
/* eslint-disable no-void */
/* eslint-disable prefer-rest-params */
/* eslint-disable prefer-spread */
/* eslint-disable no-unused-expressions */
/* eslint-disable no-multi-assign */
/* eslint-disable no-console */
/* eslint-disable no-underscore-dangle */
/* eslint-disable func-names */
/* eslint-disable import/prefer-default-export */
import React from 'react';
import { reportToSegment, types } from '@smartcar/morse';
import './src/styles/global.css';
import './src/styles/consentManager.css';

import AppContext from './src/context/AppContext';

const queryString = window.location.search;
const urlParams = new URLSearchParams(queryString);
AppContext.referringAppName = urlParams.get('referrerAppName') || '';
AppContext.referringAppUrl = urlParams.get('referrerAppUrl') || '';

export const wrapRootElement = ({ element }) => (
  <AppContext.Provider value={AppContext}>{element}</AppContext.Provider>
);

export const onRouteUpdate = () => {
  if (window.analytics) {
    reportToSegment(types.PAGE);
  }
};

export const onInitialClientRender = () => {
  // SEGMENT TRACKING
  (function () {
    const i = 'analytics';
    const analytics = (window[i] = window[i] || []);
    if (!analytics.initialize)
      if (analytics.invoked)
        window.console && console.error && console.error('Segment snippet included twice.');
      else {
        analytics.invoked = !0;
        analytics.methods = [
          'trackSubmit',
          'trackClick',
          'trackLink',
          'trackForm',
          'pageview',
          'identify',
          'reset',
          'group',
          'track',
          'ready',
          'alias',
          'debug',
          'page',
          'screen',
          'once',
          'off',
          'on',
          'addSourceMiddleware',
          'addIntegrationMiddleware',
          'setAnonymousId',
          'addDestinationMiddleware',
          'register',
        ];
        analytics.factory = function (e) {
          return function () {
            if (window[i].initialized) return window[i][e].apply(window[i], arguments);
            const n = Array.prototype.slice.call(arguments);
            if (['track', 'screen', 'alias', 'group', 'page', 'identify'].indexOf(e) > -1) {
              const c = document.querySelector("link[rel='canonical']");
              n.push({
                __t: 'bpc',
                c: (c && c.getAttribute('href')) || void 0,
                p: location.pathname,
                u: location.href,
                s: location.search,
                t: document.title,
                r: document.referrer,
              });
            }
            n.unshift(e);
            analytics.push(n);
            return analytics;
          };
        };
        for (let n = 0; n < analytics.methods.length; n++) {
          const key = analytics.methods[n];
          analytics[key] = analytics.factory(key);
        }
        analytics.load = function (key, n) {
          const t = document.createElement('script');
          t.type = 'text/javascript';
          t.async = !0;
          t.setAttribute('data-global-segment-analytics-key', i);
          t.src = `https://cdn.segment.com/analytics.js/v1/${key}/analytics.min.js`;
          const r = document.getElementsByTagName('script')[0];
          r.parentNode.insertBefore(t, r);
          analytics._loadOptions = n;
        };
        analytics._writeKey = process.env.GATSBY_SEGMENT_WRITE_KEY;
        analytics.SNIPPET_VERSION = '5.2.0';
        analytics.load(process.env.GATSBY_SEGMENT_WRITE_KEY);
        analytics.page();
      }
  })();
};
