import formatError from './formatError';

export default (error, input) => {
  if (error instanceof Error) {
    // Added to avoid a bug when running gatsby build.
    // 'WebpackError: ReferenceError: window is not defined'
    // https://www.gatsbyjs.org/docs/debugging-html-builds/
    if (typeof window !== 'undefined') {
      window.Sentry.captureException(error);
    }
  } else {
    const formattedError = formatError(error, input);
    // Added to avoid a bug when running gatsby build.
    // 'WebpackError: ReferenceError: window is not defined'
    // https://www.gatsbyjs.org/docs/debugging-html-builds/
    if (typeof window !== 'undefined') {
      window.Sentry.captureMessage(formattedError);
    }
  }
};
