import React from 'react';
import { arrayOf, bool, number, shape, string } from 'prop-types';
import { Link } from './elements';

const ModelCard = ({ region, make, model, years }) => {
  let modelYears = years[0];
  if (years.length > 1) {
    if (years[years.length - 1] === new Date().getFullYear()) {
      modelYears = `${modelYears}+`;
    } else {
      modelYears = `${modelYears}-${years[years.length - 1]}`;
    }
  }

  const formattedMake = make.toLowerCase().split(' ').join('-');
  const path = `/guides/${region !== 'US' ? `${region.toLowerCase()}/` : ''}${formattedMake}/`;

  return (
    <Link
      id={`model-card-${model.toLowerCase()}`}
      path={path}
      classnames="border-2 border-grey-medium hover:border-black rounded text-center p-6 no-underline font-normal flex flex-col align-middle"
      segmentProperties={{
        section: 'article',
        style: 'card',
        text: `${make} ${model} ${modelYears}`,
        path,
      }}
    >
      <span className="font-bold">{model}</span>
      <span>{modelYears}</span>
    </Link>
  );
};

const CustomModelCard = ({ title, subtitle, appName, path, make }) => {
  const formattedMake = make.toLowerCase().split(' ').join('-');
  const formattedPath = `/guides/${path}`;

  return (
    <Link
      id={`model-card-${formattedMake}-${subtitle}`}
      path={formattedPath}
      classnames="border-2 border-grey-medium hover:border-black rounded text-center p-6 no-underline font-normal flex flex-col align-middle"
      segmentProperties={{
        section: 'article',
        style: 'card',
        text: `${formattedMake} ${subtitle} ${appName}`,
        path,
      }}
    >
      <span className="font-bold">{title}</span>
      <span>{subtitle}</span>
      <span className="text-grey">{appName}</span>
    </Link>
  );
};

const ModelsList = ({ region, make, models, isCustomBrand }) => {
  return (
    <div className="grid gap-4 md:gap-8 grid-cols-2 md:grid-cols-3 lg:grid-cols-4">
      {models.map((model) => {
        return isCustomBrand ? (
          <CustomModelCard {...model} make={make} region={region} />
        ) : (
          <ModelCard
            region={region}
            make={make}
            model={model.model}
            years={model.years}
            key={`${make}-${model.model}-${region}`}
          />
        );
      })}
    </div>
  );
};

export default ModelsList;

ModelCard.propTypes = {
  region: string.isRequired,
  make: string.isRequired,
  model: string.isRequired,
  years: arrayOf(number).isRequired,
};

CustomModelCard.propTypes = {
  title: string.isRequired,
  subtitle: string.isRequired,
  appName: string.isRequired,
  path: string.isRequired,
  make: string.isRequired,
};

ModelsList.propTypes = {
  region: string.isRequired,
  make: string.isRequired,
  models: arrayOf(
    shape({
      model: string.isRequired,
      years: arrayOf(number).isRequired,
    }).isRequired,
  ).isRequired,
  isCustomBrand: bool.isRequired,
};
