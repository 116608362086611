const vinFormContent = (make) => {
  return {
    form: {
      heading: 'Check with VIN',
      button: 'Check compatibility',
      disclaimer:
        "If you have any questions about your vehicle's compatibility with Smartcar, please reach out to support@smartcar.com. Please note this form is only compatible with US-based VINs at this time.",
    },
    success: {
      heading: 'Congrats, your car is compatible!',
      subheading: `Your ${make} is compatible with Smartcar and eligible to connect to mobility apps. Before connecting your car, follow our instructions to create a connected services account.`,
      cta: {
        text: 'Create a connected services account',
        path: `/guides/${make !== 'vehicle' ? make : ''}`,
      },
    },
    failure: {
      heading: 'Your car is not compatible',
      subheading:
        'Unfortunately, your vehicle is not compatible with Smartcar and ineligible to connect to mobility apps.',
    },
    checkByMakeCta: {
      text: 'Check by make, model, year',
      path: '/compatibility/makes/',
    },
  };
};

export default vinFormContent;
