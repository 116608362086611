import React, { useContext, useState } from 'react';
import axios from 'axios';
import RecaptchaV2 from 'react-google-recaptcha';
import { reportToSegment, eventNames, types } from '@smartcar/morse';
import vinny from '../../utils/checkVinValidity';
import { BackToAppLink, Button, Icon, Link } from '../elements';
import vinFormContent from '../../content/vinForm';

import AppContext from '../../context/AppContext';
import handleError from '../../services/sentry/handleError';

const VinForm = () => {
  const { referringAppName, referringAppUrl } = useContext(AppContext);

  const initialFormState = {
    vin: '',
    touchedVinInput: false,
    validVin: false,
    compatibleVin: false,
    successfulRecaptcha: false,
    hasSubmitted: false,
    make: 'vehicle',
  };
  const [formState, setFormState] = useState(initialFormState);

  const handleInputChange = (e) => {
    const validVin = e && e.target && vinny.isValid(e.target.value);
    setFormState((currState) => {
      return { ...currState, vin: e.target.value, validVin };
    });
  };

  const handleInputBlur = () => {
    setFormState((currState) => {
      return { ...currState, touchedVinInput: true };
    });
  };

  const handleCompatibilityCheck = (token) => {
    axios
      .post('/.netlify/functions/vinCompatibility', {
        token,
        vin: formState.vin,
      })
      .then((response) => {
        setFormState((currState) => {
          return {
            ...currState,
            compatibleVin: response.data && response.data.compatible,
            successfulRecaptcha: true,
          };
        });
      })
      .catch((error) => {
        setFormState((currState) => {
          return {
            ...currState,
            successfulRecaptcha: true,
          };
        });
        handleError(error);
      });
  };

  const handleTokenExpire = () => {
    setFormState((currState) => {
      return { ...currState, token: null };
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setFormState((currState) => {
      return { ...currState, hasSubmitted: true };
    });
    reportToSegment(types.TRACK, eventNames.formSubmitted, {
      label: 'VIN compatibility check form',
      form_content: {
        vin: formState.vin,
      },
    });
  };

  const resetForm = () => {
    setFormState(() => {
      return { ...initialFormState };
    });
  };

  const {
    vin,
    validVin,
    touchedVinInput,
    compatibleVin,
    successfulRecaptcha,
    hasSubmitted,
  } = formState;
  const { form, success, failure, checkByMakeCta } = vinFormContent(formState.make);

  const checkAnotherVinButton = (
    <Button
      text="Check another VIN"
      onClick={resetForm}
      classnames="button-cta-secondary"
      id="check-another-vin-button"
      segmentProperties={{ label: 'action', text: 'Check another VIN' }}
    />
  );

  const compatibleScreen = (
    <>
      <h2>{success.heading}</h2>
      <p>{success.subheading}</p>
      <div>
        <Link
          path={`${success.cta.path}`}
          segmentProperties={{
            section: 'article',
            style: 'cta primary',
            text: success.cta.text,
            path: success.cta.path,
          }}
        >
          {success.cta.text} <Icon name="arrow" />
        </Link>
      </div>
      <div>{checkAnotherVinButton}</div>
    </>
  );

  const incompatibleScreen = (
    <>
      <h2>{failure.heading}</h2>
      <p>{failure.subheading}</p>
      <div>{checkAnotherVinButton}</div>
      {referringAppName && referringAppUrl && (
        <div>
          <BackToAppLink classnames="link-cta" arrow />
        </div>
      )}
    </>
  );

  const formResult = () => (compatibleVin ? compatibleScreen : incompatibleScreen);

  return (
    <div className="container bg-white md:px-12 lg:px-24 pt-8 pb-20 md:mt-24 md:py-16 space-y-2 md:space-y-4 max-w-3xl">
      {hasSubmitted ? (
        formResult()
      ) : (
        <>
          <h2>{form.heading}</h2>
          <form
            onSubmit={handleSubmit}
            id="vin-form"
            className="flex flex-col w-full space-y-6 items-start"
          >
            <p className="text-sm">{form.disclaimer}</p>
            <label htmlFor="vin" className="flex flex-col space-y-2 md:space-y-4 w-full">
              <input
                type="text"
                name="vin"
                id="vin"
                value={vin}
                onChange={handleInputChange}
                placeholder="Your VIN"
                onBlur={handleInputBlur}
              />
              {touchedVinInput && !validVin && (
                <p className="text-sm text-red-light">Please enter a valid VIN.</p>
              )}
            </label>
            {validVin && (
              <div id="recaptcha">
                <RecaptchaV2
                  sitekey={process.env.GATSBY_GOOGLE_RECAPTCHA_V2_SITE_KEY}
                  onChange={handleCompatibilityCheck}
                  onExpired={handleTokenExpire}
                />
              </div>
            )}
            <Button
              type="submit"
              text={form.button}
              id="vin-form-button"
              disabled={!successfulRecaptcha}
              // the recaptcha adds an iframe that was overlapping the button leaving the top half unclickable
              classnames="z-10"
              segmentProperties={{ label: 'backend action', text: form.button }}
            />
          </form>
          <div className="pt-4">
            <Link
              path={checkByMakeCta.path}
              classnames="link-cta"
              segmentProperties={{
                section: 'article',
                style: 'cta secondary',
                text: checkByMakeCta.text,
                path: checkByMakeCta.path,
              }}
            >
              {checkByMakeCta.text} <Icon name="arrow" />
            </Link>
          </div>
        </>
      )}
    </div>
  );
};

export default VinForm;
