import React, { useContext } from 'react';
import { BackToAppLink, Icon, Link } from './elements';
import AppContext from '../context/AppContext';
import navOptions from '../content/nav';

const Nav = () => {
  const { referringAppName, referringAppUrl } = useContext(AppContext);

  const toggleMobileMenu = () => {
    const menu = document.getElementById('menu');
    menu.classList.toggle('hidden');
  };

  const navMenuItems = navOptions.map(({ path, text }, index) => (
    <li className={`font-semibold py-3 ${index === 0 ? 'md:pr-4' : 'md:px-4'}`} key={path}>
      <Link
        path={path}
        classnames="no-underline"
        onClick={toggleMobileMenu}
        activeStyle={{ textDecoration: 'underline' }}
        segmentProperties={{
          section: 'topbar',
          style: 'inline',
          text,
          path,
        }}
      >
        {text}
      </Link>
    </li>
  ));

  return (
    <div className="fixed h-14 md:h-24 w-full bg-white z-10">
      {/* mobile menu */}
      <nav className="md:hidden h-full container relative py-4 text-right">
        <button type="button" onClick={toggleMobileMenu}>
          <Icon name="menu" />
          <span className="visually-hidden">Menu</span>
        </button>
        <div
          id="menu"
          className="hidden absolute right-0 text-right bg-white w-full px-4 py-6 space-y-6"
        >
          <ul>{navMenuItems}</ul>
          {referringAppName && referringAppUrl && <BackToAppLink />}
        </div>
      </nav>
      {/* medium screens & up menu */}
      <nav className="hidden container h-full md:flex justify-between items-center py-4">
        <ul className="flex items-center">{navMenuItems}</ul>
        {referringAppName && referringAppUrl && <BackToAppLink />}
      </nav>
    </div>
  );
};

export default Nav;
