import React from 'react';
import { bool, shape, string, object } from 'prop-types';
import { GatsbyImage } from 'gatsby-plugin-image';
import { Link } from '../elements';

const BrandCard = ({ brand, logo, path, linkToGuide }) => {
  const linkPath = linkToGuide ? `/guides/${path}/` : `/compatibility/makes/${path}/`;

  return (
    <Link
      id={`brand-card-${brand.toLowerCase().split(' ').join('-')}`}
      path={linkPath}
      classnames="border-2 border-grey-medium hover:border-black rounded text-center p-6 font-normal flex flex-col align-middle no-underline"
      segmentProperties={{
        section: 'article',
        style: 'card',
        text: brand,
        path,
      }}
    >
      <div className="h-16 flex flex-col justify-center items-center m-0-auto">
        <GatsbyImage
          image={logo.gatsbyImageData}
          alt=""
          className="max-h-full max-w-32"
          imgClassName="max-h-full m-0-auto"
        />
      </div>
      <p className="no-underline">{brand}</p>
    </Link>
  );
};

export default BrandCard;

BrandCard.propTypes = {
  brand: string.isRequired,
  logo: shape({
    // eslint-disable-next-line react/forbid-prop-types
    gatsbyImageData: object.isRequired,
  }).isRequired,
  path: string.isRequired,
  linkToGuide: bool,
};

BrandCard.defaultProps = {
  linkToGuide: false,
};
