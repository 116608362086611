import errors from './errors.json';

export default function formatError(error = {}) {
  const formattedError = { ...error };
  if (!formattedError.code || !errors[formattedError.code]) {
    formattedError.code = errors.unspecifiedError.code;
  }
  if (!formattedError.message) {
    formattedError.message = errors.unspecifiedError.message;
  }
  return `${formattedError.code}: ${formattedError.message}`;
}
