const customBrandConfigs = [
  {
    region: 'united_states',
    manufacturer: 'jeep',
    apps: [
      {
        title: 'Most Jeep models',
        subtitle: '2014-2018',
        appName: 'Uconnect',
        path: 'jeep/2014-2018',
      },
      {
        title: 'Most Jeep models',
        subtitle: '2017-2021',
        appName: 'SiriusXM',
        path: 'jeep/2017-2021',
      },
      {
        title: 'Jeep models',
        subtitle: '2021+',
        appName: 'Jeep Connect',
        path: 'jeep/2021',
      },
      {
        title: 'Jeep Wagoneer',
        subtitle: '2017+',
        appName: 'Wagoneer Connect',
        path: 'jeep/wagoneer',
      },
    ],
  },
  {
    region: 'united_states',
    manufacturer: 'chrysler',
    apps: [
      {
        title: 'Most Chrysler models',
        subtitle: '2014-2018',
        appName: 'Uconnect',
        path: 'chrysler/2014-2018',
      },
      {
        title: 'Most Chrysler models',
        subtitle: '2017-2021',
        appName: 'SiriusXM',
        path: 'chrysler/2017-2021',
      },
    ],
  },
  {
    region: 'united_states',
    manufacturer: 'dodge',
    apps: [
      {
        title: 'Most Dodge models',
        subtitle: '2014-2018',
        appName: 'Uconnect',
        path: 'dodge/2014-2018',
      },
      {
        title: 'Most Dodge models',
        subtitle: '2017-2021',
        appName: 'SiriusXM',
        path: 'dodge/2017-2021',
      },
    ],
  },
  {
    region: 'united_states',
    manufacturer: 'ram',
    apps: [
      {
        title: 'Most Ram models',
        subtitle: '2014-2018',
        appName: 'Uconnect',
        path: 'ram/2014-2018',
      },
      {
        title: 'Most Ram models',
        subtitle: '2017-2021',
        appName: 'SiriusXM',
        path: 'ram/2017-2021',
      },
    ],
  },
  {
    region: 'canada',
    manufacturer: 'jeep',
    apps: [
      {
        title: 'Most Jeep models',
        subtitle: '2014-2018',
        appName: 'Uconnect',
        path: 'ca/jeep/2014-2018',
      },
      {
        title: 'Most Jeep models',
        subtitle: '2017-2021',
        appName: 'SiriusXM',
        path: 'ca/jeep/2017-2021',
      },
      {
        title: 'Jeep models',
        subtitle: '2021+',
        appName: 'Jeep Connect',
        path: 'ca/jeep/2021',
      },
      {
        title: 'Jeep Wagoneer',
        subtitle: '2017+',
        appName: 'Wagoneer Connect',
        path: 'ca/jeep/wagoneer',
      },
    ],
  },
  {
    region: 'canada',
    manufacturer: 'chrysler',
    apps: [
      {
        title: 'Most Chrysler models',
        subtitle: '2014-2018',
        appName: 'Uconnect',
        path: 'ca/chrysler/2014-2018',
      },
      {
        title: 'Most Chrysler models',
        subtitle: '2017-2021',
        appName: 'SiriusXM',
        path: 'ca/chrysler/2017-2021',
      },
    ],
  },
  {
    region: 'canada',
    manufacturer: 'dodge',
    apps: [
      {
        title: 'Most Dodge models',
        subtitle: '2014-2018',
        appName: 'Uconnect',
        path: 'ca/dodge/2014-2018',
      },
      {
        title: 'Most Dodge models',
        subtitle: '2017-2021',
        appName: 'SiriusXM',
        path: 'ca/dodge/2017-2021',
      },
    ],
  },
  {
    region: 'europe',
    manufacturer: 'volkswagen',
    apps: [
      {
        title: 'Volkswagen vehicles',
        subtitle: 'ID series',
        appName: 'We Connect ID',
        path: 'eu/volkswagen/id-series',
      },
      {
        title: 'Volkswagen vehicles',
        subtitle: 'non-ID series',
        appName: 'We Connect',
        path: 'eu/volkswagen/non-id-series',
      },
    ],
  },
];

module.exports = customBrandConfigs;
