import React from 'react';
import { GatsbyImage } from 'gatsby-plugin-image';
import { object, shape, string } from 'prop-types';
import { eventNames, reportToSegment, types } from '@smartcar/morse';
import { Icon } from './elements';

import appStore from '../images/icons/app-store.png';
import googlePlay from '../images/icons/google-play.png';

const Hero = ({ heading, subheading, image, manufacturerInfo, theme }) => {
  const { connectedAccountCost, manufacturerWebsite, iosLink, androidLink } = manufacturerInfo;

  let bgcolor = '#005A46';
  if (theme) {
    // CMS color format: "red1_CC0000"
    bgcolor = `#${theme.split('_')[1]}`;
  }

  const handleInfoClick = (info) => {
    reportToSegment(types.TRACK, eventNames.linkClicked, {
      section: 'header',
      style: 'inline',
      text: info,
      path: info,
    });
  };

  return (
    <header className="overflow-x-hidden" style={{ backgroundColor: bgcolor }}>
      <div className="container flex text-white">
        <div className="py-10 md:py-20 md:pr-10 space-y-6 w-full md:w-3/5">
          <h1>{heading}</h1>
          {subheading && <p className="body-bold">{subheading}</p>}
          {manufacturerInfo && (
            <div>
              {connectedAccountCost && (
                <p>
                  <Icon name="dollarSign" />
                  <span className="ml-2">{connectedAccountCost}</span>
                </p>
              )}
              {manufacturerWebsite && (
                <p>
                  <Icon name="globe" />
                  <a
                    href={manufacturerWebsite}
                    className="ml-2"
                    onClick={() => handleInfoClick(manufacturerWebsite)}
                  >
                    {manufacturerWebsite}
                  </a>
                </p>
              )}
              <div className="flex gap-6 mt-4">
                {iosLink && (
                  <a target="_blank" rel="noopener noreferrer" href={iosLink}>
                    <img src={appStore} alt="Apple app store" className="h-8 sm:h-14" />
                  </a>
                )}
                {androidLink && (
                  <a target="_blank" rel="noopener noreferrer" href={androidLink}>
                    <img src={googlePlay} alt="Android google play store" className="h-8 sm:h-14" />
                  </a>
                )}
              </div>
            </div>
          )}
        </div>
        <div className="hidden md:block w-2/5 self-stretch relative">
          <div className="h-full absolute" style={{ width: '225%' }}>
            <GatsbyImage image={image.gatsbyImageData} alt="" className="h-full" />
          </div>
        </div>
      </div>
    </header>
  );
};

export default Hero;

Hero.propTypes = {
  heading: string.isRequired,
  subheading: string,
  image: shape({
    // eslint-disable-next-line react/forbid-prop-types
    gatsbyImageData: object.isRequired,
  }),
  manufacturerInfo: shape({
    manufacturer: string,
    connectedAccountCost: string,
    manufacturerWebsite: string,
    iosLink: string,
    androidLink: string,
  }),
  theme: string,
};

Hero.defaultProps = {
  subheading: '',
  image: null,
  manufacturerInfo: {},
  theme: '',
};
